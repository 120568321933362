ngx-charts-pie-chart{
  transform: rotate(90deg)!important;
}
.y .tick{
  font-family: 'Inter SemiBold', sans-serif;
  font-weight: 500;
}
.x  .tick {
  font-family: 'Inter SemiBold', sans-serif;
  opacity: 1;
  font-weight: 500;
}
.x  .tick.inactive {
  opacity: 0.3;
  font-weight: 500;
}
.x  .tick.active {
  opacity: 1 !important;
  font-weight: 500;
}
.ngx-charts .arc:hover,
.ngx-charts .bar:hover {
  opacity: unset !important;
}
.y{
  transform: translate(-10px, 0px)!important;
}
