@media (max-width: 1770px) {
  .container {
    padding: 0 20px;
  }
}
@media(max-width:  1746px){
  .nav-block .nav-item{
    width: unset!important;
  }
  .nav-block .nav-item .nav-block-label .nav-label{
    font-size: 20px!important;
  }
  .nav-block .nav-item .nav-description{
    font-size: 12px!important;
  }
}
@media screen and (max-width: 1670px) {
  .statistics-application{
   min-width: unset!important;
  }
  .statistics-event{
    min-width: unset!important;
  }
  .nav-block {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .nav-block .nav-item{
    width: unset!important;
    max-width: unset!important;
  }
}

@media screen and (max-width: 1350px) {
  .nav-block {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;

  }
}
@media screen and (max-width: 1220px) {
  .nav-block {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
@media screen and (max-width: 890px) {
  .nav-block {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
