.text-inter-extra-bold{
  font-family: 'Inter ExtraBold', sans-serif;
}
.text-inter{
  font-family: 'Inter', sans-serif;
}
.text-inter-black{
  font-family: 'Inter Black', sans-serif;
}
.text-inter-bold{
  font-family: 'Inter Bold', sans-serif;
}
.text-inter-extra-light{
  font-family: 'Inter ExtraLight', sans-serif;
}
.text-inter-light{
  font-family: 'Inter Light', sans-serif;
}
.text-inter-medium{
  font-family: 'Inter Medium', sans-serif;
}
.text-inter-regular{
  font-family: 'Inter Regular', sans-serif;
}
.text-inter-semibold{
  font-family: 'Inter SemiBold', sans-serif;
}

