@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Thin.ttf');

}
@font-face {
  font-family: 'Inter Black';
  src: url('../fonts/Inter/Inter-Black.ttf');

}
@font-face {
  font-family: 'Inter Bold';
  src: url('../fonts/Inter/Inter-Bold.ttf');

}
@font-face {
  font-family: 'Inter ExtraLight';
  src: url('../fonts/Inter/Inter-ExtraLight.ttf');

}
@font-face {
  font-family: 'Inter ExtraBold';
  src: url('../fonts/Inter/Inter-ExtraBold.ttf');

}
@font-face {
  font-family: 'Inter Light';
  src: url('../fonts/Inter/Inter-Light.ttf');

}
@font-face {
  font-family: 'Inter Medium';
  src: url('../fonts/Inter/Inter-Medium.ttf');

}
@font-face {
  font-family: 'Inter Regular';
  src: url('../fonts/Inter/Inter-Regular.ttf');

}
@font-face {
  font-family: 'Inter SemiBold';
  src: url('../fonts/Inter/Inter-SemiBold.ttf');

}
