@import "assets/style/main.css";

html, body {
  cursor: url('assets/images/ul-kit-img/cursor-hover.svg') 0 0, auto;
  width: 100%;
  height: 100%;
}


html {
  overflow-x: hidden;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #888;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #FF6B00;
  }

}
::placeholder{
  opacity: 1!important;
  color: black!important;
}
body {

  margin: 0 auto;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: 'Inter Medium', sans-serif;
}


ul, li {
  padding: unset;
  margin: unset;
}

li {
  list-style-type: none;
}

input:disabled{
  background-color: #F4F3F7;
  color: black;
}
a {
  text-decoration: none;
}


td,
th,
button,
input,
.clickable {
  cursor: url('/assets/images/ul-kit-img/cursor-hover.svg') 0 0, auto;
}
h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
}

h1, h2, h3 {
  padding: unset;
  margin: unset;
  color: #FFFFFF;
}

p {
  padding: unset;
  margin: unset;
}

span {
  padding: unset;
  margin: unset;
}
.body{
  height: auto;
  //margin: 0 auto;
  padding: 0 73px;
  //max-width: 1770px;
  //width: 100%;

}

.container {
  margin-top: 24px;


  .header {
    cursor: url('/assets/images/ul-kit-img/cursor.svg') 0 0, auto;
    background-color: #252525;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #252525;
}


/* Выпадающий список */
.dropdown {
  position: relative;

}

.dropdown-menu {
  background-color: white;
  position: absolute;
  right: 0;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;

  .menu-item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .profile-info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .info-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        color: #252525;
        white-space: nowrap;
      }

      .role-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        color: #A0A0A0;
      }
    }
  }
}
.form{
  display: flex;
  flex-direction: column;
  gap:16px;
  margin-bottom: 32px;
  transition: margin 0.6s ease-out;
}
.margin-bottom-unset {
  margin-bottom: 0;
}
.label-form{
  display: inline-flex;
  flex-direction: row;
  gap:16px;
  max-width: 240px;
  span{
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
  img{
    transition: transform 0.6s ease-out;
  }
}
.left-content{
  display: flex;
  flex-direction: column;
  gap:8px;
  min-width: 280px;
}

.form-content{
  display: flex;
  flex-direction: row;
  gap: 28px;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
  height: 0px;
}
.form-content.active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
  height: 100%;
}
.right-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:8px;
}
.item-info {
  width: 100%;
}

.healthrestrictions > * {
  flex: 0 0 50%;
  box-sizing: border-box;
}
.block-with-checkbox{
  display: flex!important;
  flex-direction: column!important;
  gap:6px!important;
}
.tools{
  display:flex;
  flex-direction: row;
  gap:8px;

}
.item-content-label:nth-child(5),
.item-content-label:nth-child(7),
.item-content-label:nth-child(6){
  min-height: 63px;
  padding-bottom: 10px;
}
.item-content-label:last-child{
  min-height: 150px;
  padding-bottom: 10px;
}
.item-content-label:nth-last-child(2){
  min-height: 150px;
  padding-bottom: 10px;
}
.item-content-label{
  display: flex;
  flex-direction: row;
  gap:16px;
  min-height:37px;
  align-items: center;

  span{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color:#A0A0A0;
  }
}


router-outlet + * {
  height: 100%;
}

