/* Для всех кнопок */
.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-raised-button,
.mat-mdc-stroked-button {
  cursor: url('/assets/images/ul-kit-img/cursor-hover.svg') auto !important;
}

/* Для всех интерактивных элементов, таких как checkbox, select, radio и т.д. */
.mat-mdc-checkbox,
.mat-mdc-select,
.mat-mdc-radio-button,
.mat-mdc-slide-toggle,
.mat-mdc-switch {
  cursor: url('/assets/images/ul-kit-img/cursor-hover.svg') auto !important;
}

/* Для всех ссылок в Angular Material */
.mat-mdc-tab-label,
.mat-mdc-button-wrapper a {
  cursor: url('/assets/images/ul-kit-img/cursor-hover.svg') auto !important;
}


.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  background-color: #FF6B00 !important;
  border-color: #FF6B00 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
  opacity: unset !important;
  background: none !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  opacity: unset !important;
  background: none !important;
}

.mdc-checkbox:hover .mdc-checkbox__ripple {
  opacity: unset !important;
  background: none !important;
}

.mat-mdc-checkbox-ripple:not(:empty), .mdc-checkbox__ripple:not(:empty) {
  transform: none !important;
}

html {
  --mdc-checkbox-state-layer-size: 12px !important;
}

.mat-mdc-checkbox-touch-target {
  height: 12px !important;
  width: 12px !important;
}

.mdc-checkbox {
  padding: unset !important;
  margin: unset !important;
  flex: unset !important;
  width: 12px !important;
  height: 12px !important;
}

.mat-mdc-select-arrow {
  display: none;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: #FF6B00 !important;
}

.custom-selection-search .mat-mdc-select-placeholder {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #252525 !important;
}

.custom-multiselect-search-event,
.custom-multiselect-search mat-label {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #A0A0A0 !important;
}

.custom-selection-search .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.custom-selection-search .mat-mdc-form-field-infix {
  min-height: unset !important;
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.custom-selection-search .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border: 2px solid #F4F3F7 !important;
  border-radius: 8px !important;
}

.custom-selection-search .mdc-text-field--outlined.mdc-text-field--focused .mat-mdc-notch-piece {
  border: 2px solid #FF6B00 !important;
  border-radius: 8px !important;
}

.mdc-text-field--outlined.mdc-text-field--focused .mat-mdc-notch-piece {
  border: 2px solid #FF6B00 !important;
  border-radius: 8px !important;
}

.custom-selection-search .mat-mdc-select-trigger {
  height: 37px;
}

.custom-multiselect-search .mat-mdc-text-field-wrapper {
  width: 278px !important;
}

.custom-multiselect-search-event .mat-mdc-text-field-wrapper {
  width: 1720px !important;
}

.custom-selection-search .mdc-notched-outline__leading {
  display: none !important;
}

.custom-selection-search .mat-mdc-select-value {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #252525 !important;
}

.mdc-list-item__primary-text {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  color: #252525 !important;
}

.mat-mdc-option .mat-pseudo-checkbox-full {
  margin-right: 8px !important;
}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: unset !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: unset !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  background-color: #FF6B00 !important;
  border-color: #FF6B00 !important;
}

.mat-internal-form-field > label {
  margin-left: 8px !important;
}

.mdc-label {
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  color: #252525 !important;
}

.mat-mdc-select-panel {
  box-shadow: 0px 4px 24px 0px #2525251A !important;
  border-radius: 8px !important;
  border: 2px solid #F4F3F7 !important;
}

.mat-mdc-option {
  min-height: 16px !important;
  margin-top: 16px !important;
}

.mat-pseudo-checkbox-full {
  border-color: #252525 !important;
}

.mdc-checkbox__background {
  border-color: #252525 !important;
}

.mat-ripple:not(:empty) {
  background: unset !important;
  opacity: unset !important;
  transform: unset !important;
  outline: unset !important;
}

.mat-mdc-select-panel {
  max-height: unset !important;
  overflow: unset !important;
}

.input-main-search {
  height: 37px !important;
  border-radius: 8px !important;
  border: 2px solid #F4F3F7 !important;
  width: 100% !important;
  padding: 0 16px !important;
  outline: unset !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}
* {
  box-sizing: border-box;
}
.input-main-search:focus {
  border-radius: 8px !important;
  border: 2px solid #FF6B00 !important;
}

.cdk-overlay-pane {
  margin-top: 8px !important;
}

.pagination-select .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  display: none !important;
}

.pagination-select .mat-mdc-select-trigger {
  border: 2px solid #F4F3F7 !important;
  border-radius: 8px !important;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding-bottom: unset !important;
  padding-top: unset !important;
}

.mat-mdc-form-field-infix {
  min-height: unset !important;
  padding-bottom: unset !important;
  padding-top: unset !important;
}


.custom-select-pagination .mdc-list-item__primary-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  color: #252525 !important;
}

.custom-paginator .mat-pseudo-checkbox {
  margin-top: 8px !important;
}

.custom-select-pagination .mat-pseudo-checkbox-minimal {
  display: none !important;
}

.custom-select-panel {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-left: 2px solid #FF6B00 !important;
  border-right: 2px solid #FF6B00 !important;
  border-bottom: unset !important;
  border-top: 2px solid #FF6B00 !important;
  box-shadow: unset !important;
}

//select pagination
.dropdown-open .mat-mdc-select-value-text {
  color: #FF6B00 !important;
}

.custom-panel .mat-mdc-option {
  min-height: 21px !important;
  margin-top: 4px !important;
}


.select-text:hover .mat-mdc-option:hover .mdc-list-item__primary-text {
  color: #FF6B00 !important;
}

.select-text .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  font-family: 'Inter SemiBold', sans-serif!important;
}

.custom-panel {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-left: 2px solid #FF6B00 !important;
  border-right: 2px solid #FF6B00 !important;
  border-bottom: unset !important;
  border-top: 2px solid #FF6B00 !important;
  box-shadow: none !important;
  padding: unset !important;
}

.custom-paginator {
  .mat-mdc-form-field {
    width: 94px !important;
  }

  .mat-mdc-select-min-line {
    font-size: 14px;
    font-weight: 400;

  }

  .mat-mdc-select-arrow-wrapper {
    height: 29px !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-bottom: 2px solid #FF6B00 !important;
    border-left: 2px solid #FF6B00 !important;
    border-right: 2px solid #FF6B00 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: unset !important;
  }

  .mat-mdc-select-value-text {
    padding: 4px 16px 4px 18px !important;
  }

  .mdc-list-item__primary-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
  }

  .mat-pseudo-checkbox {
    display: none !important;
  }

  .mat-mdc-select-arrow {
    display: none !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border: 2px solid #F4F3F7 !important;
    border-radius: 8px !important;
  }

  .mdc-notched-outline__leading {
    display: none !important;
  }

  .mdc-notched-outline__notch {
    display: none !important;
  }

  .mdc-text-field--outlined {
    padding: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;
  }

}

.mat-mdc-select-trigger {
  box-shadow: none !important;
}

//select pagination
.mat-ripple-element {
  background-color: unset !important;
  opacity: unset !important;
}


.custom-datepicker .mat-mdc-form-field-infix {
  padding-top: 6px !important;
}

.custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset !important;
  border: 2px solid #F4F3F7 !important;
  border-radius: 8px !important;
  height: 37px !important;
}

.custom-datepicker .mat-mdc-form-field-focus-overlay {
  background-color: unset !important;
}

.custom-datepicker .mat-mdc-icon-button {
  background-color: unset !important;
}

.custom-datepicker .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: unset;
}


.custom-datepicker .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  background-color: unset !important;
}

.custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: unset !important;
}

.custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: unset !important;
}

.custom-datepicker .mdc-line-ripple::before {
  border-bottom-width: unset !important;
}

.custom-datepicker .mdc-line-ripple::before, .mdc-line-ripple::after {
  content: unset !important;
}

.custom-datepicker .mat-mdc-form-field-icon-suffix {
  padding: unset !important;
}

.custom-datepicker .mat-mdc-form-field-flex {
  height: 37px !important;
}

.custom-datepicker .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  color: #252525 !important;
  font-family: 'Inter SemiBold', sans-serif!important;
}

.custom-datepicker .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 12px !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: unset !important;
  border: unset !important;
}

.mat-calendar-body-selected {
  background-color: #FF6B00 !important;
}

.mat-calendar-body-cell-content {
  border-radius: 4px !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #FF6B00 !important;
  border: unset !important;
  color: white !important;
}

.mat-datepicker-content .mat-calendar-previous-button:not(.mat-mdc-button-disabled), .mat-datepicker-content .mat-calendar-next-button:not(.mat-mdc-button-disabled) {
  background-color: #FF6B00 !important;
  color: white !important;
}

.mat-calendar-arrow {
  display: none !important;
}

.mat-calendar-controls {
  gap: 8px !important;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 38px !important;
}

.mat-calendar-body-today {
  background-color: transparent !important;
  color: #252525 !important;
}

.mat-calendar-body-label {
  opacity: 0 !important;
}

.mat-calendar-body-label[colspan="7"] {
  display: none !important;
}

.mat-datepicker-content {
  box-shadow: 0px 4px 24px 0px #2525251A !important;
  border-radius: 16px !important;
}

.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: unset !important;
}

.mat-mdc-button .mat-mdc-button-persistent-ripple::before {
  background-color: unset !important;
}

.mat-datepicker-content .mat-calendar {
  height: unset !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  background-color: unset !important;
}

.mat-calendar-table-header-divider::after {
  content: unset !important;
}

.mat-calendar-body-disabled .mat-calendar-body-cell-content {
  color: #FF6B00 !important;
}

.mat-calendar-table-header th:nth-child(6),
.mat-calendar-table-header th:nth-child(7) {
  color: #FF6B00;
}

.mat-calendar-body-cell {
  width: 28px !important;
  height: 28px !important;
  padding-left: 3px !important;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  margin-left: 3px !important;
}

.mat-calendar-table-header th {
  font-family: 'Inter SemiBold' !important;
  color: #252525;
}

.mat-calendar-body-cell-content mat-focus-indicator {
  font-family: 'Inter Medium' !important;
}

.mat-mdc-button:not(:disabled) {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  font-family: 'Inter Medium', sans-serif !important;
}

.mat-datepicker-toggle-active {
  color: #FF6B00 !important;
}

.custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled):focus-within,
.custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled) .mat-datepicker-toggle.active {
  border-color: #FF6B00 !important;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 32px !important;
  height: 32px !important;
}

.mat-calendar-previous-button::after {
  top: -4px !important;
  left: -4px !important;
  width: 7px !important;
  height: 7px !important;
}

.mat-calendar-next-button::after {
  top: -4px !important;
  left: -4px !important;
  width: 7px !important;
  height: 7px !important;
}

.mat-calendar-previous-button svg,
.mat-calendar-next-button svg {
  position: relative;
  top: -4px !important;
  left: -4px !important;
}


/* Отключаем клики на ячейках месяца */
.mat-mdc-button:not(:disabled) {
  pointer-events: none !important;
}

.weekend .mat-calendar-body-selected {
  color: #FFFFFF !important;
}

.mat-calendar-body-selected.mat-calendar-body-today {
  background-color: #FF6B00 !important;
  color: #FFFFFF !important;
  border: unset !important;
}

.weekend .mat-calendar-body-cell-content {
  color: #FF6B00;
}

.item-info .mat-mdc-form-field {
  width: 100% !important;
}

.mat-form-field-disabled {
  pointer-events: none !important; /* Запретить все события */
  opacity: 0.5 !important; /* Визуально показать отключение */
}

.mat-form-field-disabled {
  background-color: #F4F3F7 !important;
  border-radius: 8px !important;
  opacity: unset !important;
  border: unset !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mat-mdc-notch-piece {
  border-color: unset !important;
  flex-grow: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.mat-mdc-notch-piece {
  border-top: unset !important;
  border-bottom:unset !important;
}
.mdc-text-field--filled.mdc-text-field--disabled{
  background-color: #F4F3F7 !important;
  border-radius: 8px !important;
}
.custom-checkbox .mdc-checkbox__background{
  width:14px!important;
  height:14px!important;
}
.custom-checkbox{
  margin-left: 6px!important;
}
.custom-checkbox .mat-internal-form-field > label{
  margin-bottom:4px!important;
}
 .mat-mdc-checkbox label{
  cursor:unset!important;
   pointer-events: none!important;
}
.custom-checkbox .mdc-checkbox__native-control:disabled:checked~.mdc-checkbox__background{
  background-color: #FF6B00 !important;
  border-color: #FF6B00 !important;
}
.mat-calendar-header button.mat-calendar-period-button {
  font-family: 'Inter SemiBold', sans-serif!important;
  text-transform: lowercase;
  font-weight: 500!important;
}
.mat-calendar-header button.mat-calendar-period-button span:first-letter {
  font-family: 'Inter SemiBold', sans-serif!important;
  text-transform: uppercase;
  font-weight: 500!important;
}
.mat-calendar-table-header th:nth-of-type(-n+5) {
  color: #252525 !important;
}
.select-text .mat-mdc-option:first-child {
  margin-top: 0 !important;
}
.filter-modal-input.mat-mdc-input-element{
  border: 2px solid #F4F3F7!important;
  border-radius: 8px!important;
  height:31px!important;
  outline: unset!important;
  padding-left: 16px!important;
  color: black!important;
  width:100%
}
.checkbox-selected .mdc-label{
  font-size: 10px !important;
}
.main-check-box .mdc-label{
  font-size: 10px !important;
}
.checkbox-selected .mdc-form-field.mat-internal-form-field{
  margin-top:3px!important;
  margin-bottom:3px!important;
}
.select-header .custom-datepicker .mat-mdc-form-field-infix{
  width: 100%!important;
}
.select-header .custom-datepicker .mat-mdc-icon-button.mat-mdc-button-base{
  padding: 0 0 2px 0!important;
}
.select-header .custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled):focus-within,
.select-header .custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled) .mat-datepicker-toggle.active {
  border:2px solid #F4F3F7!important;
}
.select-header .custom-datepicker .mdc-text-field--filled:not(.mdc-text-field--disabled){
  height:31px!important;
}

.custom-filter-table .mat-mdc-option .mdc-list-item__primary-text{
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  font-family: "Inter SemiBold", sans-serif !important;
}
.custom-filter-table  .mdc-text-field--outlined {
  height: 31px!important;
}
.custom-filter-table.mat-mdc-form-field{
  width:100%!important;
}
.multi-datepicker{
  width:100% !important
}
.multi-datepicker.mat-mdc-form-field{
  width:100%!important;
}
.custom-filter-table .mat-mdc-option.mdc-list-item{
  min-height: 30px !important;
  margin-top: 0px !important;
}
.mat-calendar-body-in-range::before{
  background-color: #FF6B00 !important;
  border-radius: 4px!important;
}
.mat-calendar-body-in-range .mat-calendar-body-cell-content{
  color: white!important;
}
.multi-datepicker .mat-date-range-input-container{
  width:162px!important;
}
.checkbox-lable.mat-mdc-checkbox .mat-internal-form-field{
  width: 100px!important;
}
.checkbox-selected .mdc-label{
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: block!important;
  width: 200px!important;
  text-align: left!important;
}
.message-textarea{
  width: 360px;
  max-width: 360px;
  min-width: 360px;
  min-height: 100px;
  max-height: 200px;
  border: 1px solid #F4F3F7;
  outline: unset!important;
  border-radius: 8px;
  padding-left:10px!important;
}

.button-green:disabled{
  opacity: 30%;
}
.date-and-time-picker-custom.multi-datepicker input[readonly]{
  background-color:unset!important;
}
.mtx-datetimepicker-content-container-with-actions .mtx-calendar .mtx-calendar-header{
  display:none!important;
}
.mtx-datetimepicker-content[mode=auto] .mtx-calendar{
  width:310px!important;
  height:296px!important;
}
.mtx-datetimepicker-actions:before{
  display:none!important;
}

.date-and-time-picker-custom.mdc-text-field--filled:not(.mdc-text-field--disabled){

}
.date-and-time-picker-custom .mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color:unset!important;
}
.date-and-time-picker-custom .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{
  border: unset!important;
}
.date-and-time-picker-custom.mat-mdc-form-field{
  height: 37px !important;
}
.date-and-time-picker-custom .mat-mdc-text-field-wrapper{
  border-radius: 8px !important;
  border: 2px solid #F4F3F7 !important;
  width: 100% !important;
  outline: unset !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}
.date-and-time-picker-custom .mat-mdc-form-field-focus-overlay{
  background-color:unset!important;
}
.date-and-time-picker-custom  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control{
  height:37px!important;
}
.date-and-time-picker-custom .mat-mdc-form-field-flex{
  height:37px!important;
}
.date-and-time-picker-custom .mdc-line-ripple::before{
  border:unset!important;
}
.mtx-datetimepicker-content{
  border-radius:8px!important;
  background-color:#FFFFFF!important;
  box-shadow: 0px 4px 24px 0px rgba(37, 37, 37, 0.1019607843) !important;
}
.mtx-calendar-body-cell:not(.mtx-calendar-body-disabled):hover>.mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected){
  background-color: #FF6B00!important;
  color: white;
  border-radius: 4px;
}
.mtx-calendar-body-selected{
  background-color: #FF6B00!important;
  color: white!important;
  border-radius: 4px!important;
  box-shadow:unset!important;
}
.mtx-calendar-body-cell-content{
  width:28px!important;
  height:28px!important;
  font-size: 14px!important;
}
.mtx-calendar-table-header th{
  font-size:11px!important;
}
.mtx-calendar-body-today:not(.mtx-calendar-body-selected){
  border: unset!important;
}
.mtx-clock-cell.mtx-clock-cell-selected{
  background-color: #FF6B00!important;
}
.mtx-clock-center{
  background-color: #FF6B00!important;
  outline: unset;
}
.mtx-clock-hand{
  background-color: #FF6B00!important;
}
.mtx-clock-cell:not(.mtx-clock-cell-selected,.mtx-clock-cell-disabled):hover{
  background-color: #FF6B00!important;
  color: white!important;
}
.mtx-clock-hand:before{
  background-color: #FF6B00!important;
}
.mtx-datetimepicker-toggle-active {
  color:#FF6B00!important;
}
.mtx-calendar-table-header tr th{
  text-transform: capitalize!important;
}
.mtx-calendar-table-header tr th:last-child{
  color:#FF6B00!important;
}
.mtx-calendar-table-header tr th:nth-last-child(2) {
  color: #FF6B00 !important;
}
.mtx-calendar-period-button{
  text-transform: capitalize!important;
  font-size: 12px!important;
}
.row .mtx-calendar-body-label:last-child{
  color:#FF6B00!important;
}
//.mtx-calendar-body tr td:last-child .mtx-calendar-body-cell-content{
//   color: #FF6B00!important;
//}
//.mtx-calendar-body tr td:nth-last-child(2) .mtx-calendar-body-cell-content{
//  color: #FF6B00!important;
//}

.mtx-clock-wrapper{
  background-color:unset!important;
  border:2px solid #F4F3F7;
}

.mtx-calendar-period-button{
  order: 1;
  margin-right: auto;
}
.mtx-calendar-next-button{
  order: 2;

}
.mtx-calendar-previous-button{
  order: 2;

}
.mtx-calendar-header{
  display:none!important;
}
.mtx-calendar-controls{
  padding-left: 15px;
}
.mtx-calendar-body tr:first-child td:last-child .mtx-calendar-body-cell-content{
  color:#FF6B00;
}
.mtx-calendar-body tr:first-child td:nth-last-child(2) .mtx-calendar-body-cell-content{
  color:#FF6B00;
}

.mtx-calendar-body tr:nth-child(2) td:last-child .mtx-calendar-body-cell-content{
  color:#FF6B00;
}
.mtx-calendar-body tr:nth-child(2) td:nth-last-child(2) .mtx-calendar-body-cell-content{
  color:#FF6B00;
}

.mtx-calendar-body tr:nth-child(3) td:last-child .mtx-calendar-body-cell-content{
  color:#FF6B00;
}
.mtx-calendar-body tr:nth-child(3) td:nth-last-child(2) .mtx-calendar-body-cell-content{
  color:#FF6B00;
}

.mtx-calendar-body tr:nth-child(4) td:last-child .mtx-calendar-body-cell-content{
  color:#FF6B00;
}
.mtx-calendar-body tr:nth-child(4) td:nth-last-child(2) .mtx-calendar-body-cell-content{
  color:#FF6B00;
}

.mtx-calendar-body tr:nth-child(5) td:nth-child(7) .mtx-calendar-body-cell-content{
  color:#FF6B00;
}
.mtx-calendar-body tr:nth-child(5) td:nth-child(6) .mtx-calendar-body-cell-content{
  color:#FF6B00;
}
.mtx-calendar-body-active>.mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected){
  color:white!important;
  background-color: #FF6B00!important;
  border-radius: 8px!important;
}
.mtx-calendar-previous-button svg, .mtx-calendar-next-button svg{
  width:30px!important;
  height:30px!important;
  background-color: #FF6B00!important;
  border-radius: 24px!important;
  color:white!important;
}
.mtx-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base{
  width: 42px!important;
}

.cdk-virtual-scrollable::-webkit-scrollbar {
  height: 5px!important;
  width: 6px!important;
  border-radius: 17px!important;
  background-color: #E6E6E6!important;
}
.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper{
  min-height: 100%!important;
}
