//-------------colors
$color-green: #00AA40;
$color-white: #ffffff;
$color-error: #E34D4D;
$color-light-error: #FF6B6B;
$color-orange: #FF6B00;
$color-light-orange: #FF8900;
$color-light-green: #40BA6E;
$color-dark: #252525;
//-------------colors

.no-data{
  color: #808080!important;
}
.no-photo{
  height: unset!important;
  min-height: 37px!important;
}
.no-photo .tools{
  flex:unset!important;
}
.no-photo .tools button{
  width: 100%;
}
.count-member {
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  color: $color-orange;
}
input[readonly] {
  background-color: #F4F3F7;
  pointer-events: none;
}
.disabled-button {
  background-color: #F4F3F7!important;
}
.count {
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  color: $color-green;
}
.button-classic{
  padding: unset;
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.button-dark{
  cursor: pointer;
  background-color: $color-dark;
  border: none;
  border-radius: 8px;
  padding: 4px 10px;


  .cancel {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #FFFFFF;
  }
}
.button-red {
  cursor: pointer;
  background-color: $color-error;
  border: none;
  border-radius: 8px;
  padding: 4px 10px;
  min-width: 100px;

  &:hover{
    background-color: $color-light-error;
  }

  .cancel {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #FFFFFF;
  }
}

.button-green {
  outline: none;
  cursor: pointer;
  background-color: $color-green;
  border: none;
  border-radius: 8px;
  padding: 4px 10px;
  min-width: 100px;

  &:hover{
    background-color: $color-light-green;
  }
  .accept {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #FFFFFF;
  }
}

.bth-orange {
  background-color: $color-orange;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;

  &:hover{
    background-color: $color-light-orange;
  }

  .text-bth {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
}

.other-info {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .setting-profile-text {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #252525;
  }

  .exit-block {
    display: flex;
    flex-direction: row;
    gap: 8px;

    span {
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      color: $color-error;
    }
  }
}
.hide{
  color: transparent!important;
}
.active-dropdown {

  color: $color-orange !important;
}

.rotate-180 {
  transform: rotate(180deg);
}
.arrow-custom-select{
  transition: transform 0.3s;
  position: absolute;
  right: 1px;
  top: 12px;
}

.close-bth{
  width: 32px;
  height: 32px;
  background-color: $color-error;
  border: none;
  border-radius: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px!important;
  outline: unset!important;
}
.snow-massage{
  right:1px;
  display:none;
  position:absolute;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #87CEEB, #00BFFF, #ffffff);
  border: 2px solid #fff;
  border-radius: 12px;
  padding: 15px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: nowrap;
  animation: glow 2s infinite alternate;

  .info-text {
    font-size: 10px;
    text-shadow: 0 0 10px #fff, 0 0 20px #87CEEB, 0 0 30px #00BFFF;
  }
}


.popup-enabled {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.popup-enabled:hover::after {
  content: attr(data-full-text);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: normal;
  background: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  z-index: 10;
  width: 200px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  word-wrap: break-word;
  display: block;
}

.popup-enabled:hover {
  overflow: visible;
}
