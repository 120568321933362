.toast-error {
  font-family: 'Inter Medium', sans-serif;
  background-color: #252525!important;
  border-radius:16px!important;
}
.toast-success {
  font-family: 'Inter Medium', sans-serif;
  background-color: #00AA40!important;
  border-radius:16px!important;
}
.toast-warning{
  font-family: 'Inter Medium', sans-serif;
  background-color: #FF6B00!important;
  border-radius:16px!important;
}
.toast-info{
  font-family: 'Inter Medium', sans-serif;
  background-color: #E34D4D !important;
  border-radius:16px!important;
}
